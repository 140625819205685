import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import login from './pages/login'
import Loading from './pages/loading'
import Loadingout from './pages/loadingout'
import Directload from './pages/direkload'
import Routes from './routes/Routes'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={login} />
        <Route path="/loading" exact component={Loading} />
        <Route path="/direkload/:un" exact component={Directload} />
        <Route path="/loadingout" exact component={Loadingout} />
        {/* routes  */}
        <Routes/>
        {/* no routes  */}
        <Route component={Loadingout} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import API from '../axios/api';
import key from '../axios/key';

export default class editprofile extends Component {

  constructor() {
    super()
    this.state = {
      profile: [],
      unusers: '',
      idusers: '',
      re_password: '',
      passbaru: '',
      epassword: '0',
      eprofile: '1',
      filedatas: '0',
      cek1: '0',
      cek2: '0',
      cek3: '0',
      foto: '',
      email:''
    }
  }

  showPw() {
    var x = document.getElementById('myInput')
    if (x.type === 'password') {
      x.type = 'text'
      document.getElementById('asem').style.color = '#32a2e3'
      // document.getElementById("asem").className.replace('fa fa-eye-slash')
    } else {
      x.type = 'password'
      document.getElementById('asem').style.color = '#919191'
    }
  }
  showPw3() {
    var x = document.getElementById('myInput3')
    if (x.type === 'password') {
      x.type = 'text'
      document.getElementById('y').style.color = '#32a2e3'
    } else {
      x.type = 'password'
      document.getElementById('y').style.color = '#919191'
    }
  }
  showPw2() {
    var x = document.getElementById('myInput2')
    if (x.type === 'password') {
      x.type = 'text'
      document.getElementById('asemz').style.color = '#32a2e3'
      // document.getElementById("asem").className.replace('fa fa-eye-slash')
    } else {
      x.type = 'password'
      document.getElementById('asemz').style.color = '#919191'
    }
  }

  async componentDidMount() {
    const user = localStorage.getItem("un");
    await API.get('cari/id/sign/cari' + key.baseURL + '&id=' + user)
      .then(res =>
        this.setState({
          idusers: res.data[0].id,
          unusers: res.data[0].user_id,
          yoi: res.data[0],
          // - - - - - - - editprofile - - - - - - - - - - -
          user_id: res.data[0].abon,
          user_name: res.data[0].acas,
          alamat_sekarang: res.data[0].cincau,
          no_hp: res.data[0].cuanki,
          email: res.data[0].cuantid,
          nidn: res.data[0].donat,
          posisi1: res.data[0].gulali,
          posisi2: res.data[0].lolipop,
          jabatan: res.data[0].permen,
          tgl_masuk: res.data[0].apem,
          tgl_keluar: res.data[0].bakwan,
          tempat: res.data[0].cucur,
          tanggal_lahir: res.data[0].duren,
          jenis_kelamin: res.data[0].duku,
          no_ktp: res.data[0].fuyunghai,
          status_nikah: res.data[0].gudeg,
          namapendek: res.data[0].namapendek,
          //  - - - - - - - end edit - - - - - - - - - - - - -
        })
      )
  }
  ck = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value,
    })
  }
  ck2 = (e) => {
    e.preventDefault()
    window.location.replace('#bottom')
    this.setState({
      [e.target.name]: e.target.value,
    })
  }
  ck3 = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value,
    })
  }
  handleSubmit1 = async (event) => {
    event.preventDefault()
    const user = localStorage.getItem("un");
    await API.post('update/profile/upd' + key.baseURL + '&id=' + user, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas
        })
      )
    if (this.state.filedatas == 1) {
      alert("Berhasil !!")
      window.location.reload();
    } else {
      alert("Gagal !")
    }
  }
  handleSubmit2 = async (event) => {
    event.preventDefault()
    var password = document.getElementById('myInput3').value;
    var password2 = document.getElementById('myInput2').value;
    if (password2 !== password) {
      alert('Re-Type Password Tidak Sesuai');
    }
    else {
      const user = localStorage.getItem("un");
      await API.post('updt/pss/up' + key.baseURL + '&id=' + user, this.state)
        .then(Response =>
          this.setState({
            filedatas: Response.data[0].filedatas,
          })
        )
      if (this.state.filedatas == 1) {
        alert("Berhasil !!")
        window.location.reload();
      } else {
        alert("Gagal !")
      }
    }
  }

  // ------------- Validasi Edit Profile ------------------- // 
  validasiSTS = (e) => {
    var status = document.getElementById('status_nikah').value
    if ((status = null)) {
      document.getElementById('status_nikah_1').innerHTML = 'Status Belum Diisi'
      document.getElementById('status_nikah_11').innerHTML = ''
    } else {
      document.getElementById('status_nikah_1').innerHTML = ''
      document.getElementById('status_nikah_11').innerHTML = 'Status Sudah Sesuai'
    }
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  validasiGender = (e) => {
    var gender = document.getElementById('jenis_kelamin').value
    if ((gender = null)) {
      document.getElementById('jenis_kelamin_1').innerHTML =
        'Jenis Kelamin Belum Diisi'
      document.getElementById('jenis_kelamin_11').innerHTML = ''
    } else {
      document.getElementById('jenis_kelamin_1').innerHTML = ''
      document.getElementById('jenis_kelamin_11').innerHTML =
        'Jenis Kelamin Sudah Sesuai'
    }
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  validasiTGLL = (e) => {
    var tgllahir = document.getElementById('tanggal_lahir').value
    if (tgllahir !== null) {
      document.getElementById('tanggal_lahir_1').innerHTML = ''
      document.getElementById('tanggal_lahir_11').innerHTML =
        'Tempat Lahir Sudah Sesuai'
    } else {
      document.getElementById('tanggal_lahir_1').innerHTML =
        'Tanggal Lahir Belum Diisi'
      document.getElementById('tanggal_lahir_11').innerHTML = ''
    }
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    // ------------- Validasi Edit Profile ------------------- // 
    function validasiTL() {
      var tempatlahir = document.getElementById('tempat').value
      var tl = tempatlahir.length
      if (tl < 1) {
        document.getElementById('tempat_11').innerHTML = 'Tempat Lahir Belum Diisi'
        document.getElementById('tempat_1').innerHTML = ''
      } else if ((tl = null)) {
        document.getElementById('tempat_11').innerHTML = 'Tempat Lahir Belum Diisi'
        document.getElementById('tempat_1').innerHTML = ''
      } else {
        document.getElementById('tempat_11').innerHTML = ''
        document.getElementById('tempat_1').innerHTML = 'Tempat Lahir Sudah Sesuai'
      }
    }
    function validasiUser() {
      var user = document.getElementById('user_nama').value
      var panjanguser = user.length
      if (panjanguser < 1) {
        document.getElementById('user_1').innerHTML = 'Nama Belum Diisi'
        document.getElementById('user_11').innerHTML = ''
      } else if ((panjanguser = null)) {
        document.getElementById('user_1').innerHTML = 'Nama Belum Diisi'
        document.getElementById('user_11').innerHTML = ''
      } else {
        document.getElementById('user_1').innerHTML = ''
        document.getElementById('user_11').innerHTML = 'Nama Sudah Sesuai'
      }
    }
    function validasiKTP() {
      var ktp = document.getElementById('ktp').value
      var panjangktp = ktp.length
      if (panjangktp == 20) {
        document.getElementById('val_ktp').innerHTML =
          'Mencapai batas maksimum karakter'
      } else {
        document.getElementById('val_ktp').innerHTML = ''
      }
    }

    // ------------- Validasi Edit Password ------------------- // 
    function validasiPassword() {
      var password = document.getElementById('myInput').value
      var passnya = password.length
      if (passnya < 8) {
        document.getElementById('2').innerHTML =
          '<i>Password Minimal 8 Karakter</i>'
        document.getElementById('22').innerHTML = ''
      } else if (passnya < 1) {
        document.getElementById('2').innerHTML = '<i>Password Belum Diisi</i>'
        document.getElementById('22').innerHTML = ''
      } else if ((passnya = null)) {
        document.getElementById('2').innerHTML = ''
        document.getElementById('22').innerHTML = ''
      } else {
        document.getElementById('2').innerHTML = ''
        document.getElementById('22').innerHTML = '<i>Password Sudah Sesuai</i>'
      }
    }
    function validasiPassword3() {
      var password = document.getElementById('myInput2').value
      var passnya = password.length
      if (passnya < 8) {
        document.getElementById('4').innerHTML =
          '<i>Password Minimal 8 Karakter</i>'
        document.getElementById('44').innerHTML = ''
      } else if (passnya < 1) {
        document.getElementById('4').innerHTML = '<i>Password Belum Diisi</i>'
        document.getElementById('44').innerHTML = ''
      } else if ((passnya = null)) {
        document.getElementById('4').innerHTML = ''
        document.getElementById('44').innerHTML = ''
      } else {
        document.getElementById('4').innerHTML = ''
        document.getElementById('44').innerHTML =
          '<i>Password Baru Sudah Sesuai</i>'
      }
    }
    function validasiPassword2() {
      var password = document.getElementById('myInput3').value
      var password2 = document.getElementById('myInput2').value
      var repass = password2.length
      if (password2 == password) {
        document.getElementById('33').innerHTML =
          '<i>Password baru sudah sesuai</i>'
        document.getElementById('3').innerHTML = ''
      } else if (repass < 8) {
        document.getElementById('3').innerHTML =
          '<i>Password Minimal 8 Karakter</i>'
        document.getElementById('33').innerHTML = ''
      } else if (repass < 1) {
        document.getElementById('3').innerHTML = '<i>Password Belum Diisi</i>'
        document.getElementById('33').innerHTML = ''
      } else if ((repass = null)) {
        document.getElementById('3').innerHTML = ''
        document.getElementById('33').innerHTML = ''
      } else {
        document.getElementById('33').innerHTML = ''
        document.getElementById('3').innerHTML = '<i>Password tidak sesuai</i>'
      }
    }
    const status = (val) => {
      if (val == 0) return 'Menikah'
      else if (val == 1) return 'Belum Menikah'
      else if (val == 2) return 'Duda/Janda'
      else return '-- Pilih Status --'
    }
    const jns_kelamin = (val) => {
      if (val == 0) return 'Pria'
      else if (val == 1) return 'Wanita'
      else return '-- Pilih Jenis Kelamin --'
    }
    return (
      <Fragment>
        <div className="wrapper">
          <section className="call-action">
            <div className="content">
              <figure>
                <img src="images/logoasia.png" className="logoku" />
                <Link to="/beranda">
                  <div className="profil">
                    <span className="fa fa-arrow-left" />&nbsp; Kembali Menu
                  </div>
                </Link>
              </figure>
            </div>
          </section>
          {/* profile akun  */}
          <section className="container">
            <div className="blog-single-details info-box">
              <h2 style={{ fontWeight: '600', fontSize: '22px', lineHeight: '16px', color: '#bbb7b7' }}>
                <i className="fa fa-address-book"></i>&nbsp; PROFIL AKUN
              </h2>
              <hr className="hr" />

              <ul className="">
                <li className="status nama-profil">{this.state.user_name} </li>
                <li className="status username-profil">User ID : {this.state.user_id}</li>
                <hr className="hr" />
              </ul>
              <section className="profile-left" style={{ marginTop: '25px' }}>
                <ul>
                  <li>
                    <div className="profile-right-list posisi1">
                      <h2>Posisi 1</h2>
                      <span>{this.state.posisi1}</span>
                    </div>
                  </li>
                  <li>
                    <div className="profile-right-list posisi2">
                      <h2>Posisi 2</h2>
                      <span>{this.state.posisi2}</span>
                    </div>
                  </li>
                  <li>
                    <div className="profile-right-list jabatan">
                      <h2>Jabatan</h2>
                      <span>{this.state.jabatan}</span>
                    </div>
                  </li>
                </ul>
              </section>
            </div>
            <div className="blog-single-content">
            </div>
          </section>
          {/* end profile akun  */}
          {/* edit profile  */}
          <div className="container" id="formedit">
            <div className="leave-comment message">
              <h2 style={{ fontWeight: '600', fontSize: '22px', lineHeight: '16px', color: '#bbb7b7' }}>Detail Profil</h2>
              <hr className="hr" />
              <br></br>
              <form onSubmit={this.handleSubmit1} id="form_contact">
                <div className="section1">
                  <div className="edit-input">
                    <label style={{ color: 'silver' }}>Nama User :</label>
                    <input onChange={this.ck} id="user_nama" onKeyUp={validasiUser} className="input-subject" type="text" name="user_name" defaultValue={this.state.user_name} placeholder="contoh: Andi Bagus" required />
                    <div className="responsive-validation">
                      <a id="user_1" style={{ color: 'red' }}></a>
                      <a id="user_11" style={{ color: 'yellow' }}></a>
                    </div>
                  </div>
                  <div className="edit-input">
                    <label style={{ color: 'silver' }}>Nama Panggilan :</label>
                    <input onChange={this.ck} className="input-subject" type="text" name="namapendek" defaultValue={this.state.namapendek} placeholder="contoh: Andi" />
                  </div>
                  <div className="edit-input">
                    <label className="label_edit" style={{ color: 'silver' }}>Tempat Lahir :</label>
                    <input onChange={this.ck} onKeyUp={validasiTL} id="tempat" className="input-subject" type="text" name="tempat" defaultValue={this.state.tempat} placeholder="contoh: Malang" required />
                    <div className="responsive-validation">
                      <a id="tempat_11" style={{ color: 'red' }}></a>
                      <a id="tempat_1" style={{ color: 'yellow' }}></a>
                    </div>
                  </div>
                  <div className="edit-input">
                    <label className="label_edit" style={{ color: 'silver' }}>Jenis Kelamin :</label>
                    <select onChange={this.validasiGender} defaultValue={this.state.jenis_kelamin} className="input-subject" id="jenis_kelamin" name="jenis_kelamin" required>
                      <option defaultValue={this.state.jenis_kelamin} selected hidden>{jns_kelamin(this.state.jenis_kelamin)}</option>
                      <option value="0">Pria</option>
                      <option value="1">Wanita</option>
                    </select>
                    <div className="responsive-validation">
                      <a id="jenis_kelamin_1" style={{ color: 'red' }}></a>
                      <a id="jenis_kelamin_11" style={{ color: 'yellow' }}></a>
                    </div>
                  </div>
                  <div className="edit-input">
                    <label className="label_edit" style={{ color: 'silver' }}>Alamat Sekarang :</label>
                    <textarea onChange={this.ck} id="alamat" className="input-subject" type="text-area" name="alamat_sekarang" defaultValue={this.state.alamat_sekarang} placeholder="contoh: Jalan/RT/RW/Desa/Kecamatan" />
                    <div className="responsive-validation">
                      <a id="alamat_11" style={{ color: 'yellow' }}></a>
                    </div>
                  </div>
                </div>
                <div className="section2">
                  <div className="edit-input2">
                    <label className="label_edit" style={{ color: 'silver' }}>NIDN :</label>
                    <input onChange={this.ck} id="nidn" defaultValue={this.state.nidn} className="input-subject" type="number" onInput={(e) => { e.target.value = e.target.value.slice(0, 12) }} min="0" name="nidn" placeholder="contoh: 0712345678" ></input>
                    <div className="responsive-validation">
                      <a id="1" style={{ color: 'red' }}></a>
                      <a id="11" style={{ color: 'yellow' }}></a>
                    </div>
                  </div>
                  <div className="edit-input2">
                    <label className="label_edit" style={{ color: 'silver' }}>Tanggal Lahir :</label>
                    <input onChange={this.validasiTGLL} id="tanggal_lahir" className="input-subject" type="date" name="tanggal_lahir" defaultValue={this.state.tanggal_lahir} placeholder="Tanggal Lahir *" required />
                    <div className="responsive-validation">
                      <a id="tanggal_lahir_1" style={{ color: 'red' }}></a>
                      <a id="tanggal_lahir_11" style={{ color: 'yellow' }}></a>
                    </div>
                  </div>
                  <div className="edit-input2">
                    <label className="label_edit" style={{ color: 'silver' }}>Status :</label>
                    <select onChange={this.validasiSTS} className="input-subject" defaultValue={this.state.status_nikah} id="status_nikah" name="status_nikah" required>
                      <option defaultValue={this.state.status_nikah} selected hidden>{status(this.state.status_nikah)}</option>
                      <option value="0">Menikah</option>
                      <option value="1">Belum Menikah</option>
                      <option value="2">Duda/Janda</option>
                    </select>
                    <div className="responsive-validation">
                      <a id="status_nikah_1" style={{ color: 'red' }}></a>
                      <a id="status_nikah_11" style={{ color: 'yellow' }}></a>
                    </div>
                  </div>
                  <div className="edit-input2">
                    <label className="label_edit" style={{ color: 'silver' }}>Nomor Hp :</label>
                    <input onChange={this.ck} id="no_hp" className="input-subject" type="number" name="no_hp" min="0" defaultValue={this.state.no_hp} placeholder="+62 (contoh: 81234567890" />
                  </div>
                  <div className="edit-input2">
                    <label className="label_edit" style={{ color: 'silver' }}>Email :</label>
                    <input onChange={this.ck} id="email" className="input-subject" type="email" name="email"  defaultValue={this.state.email} placeholder="wawan@gmail.com" />
                  </div>
                  <div className="edit-input2">
                    <label className="label_edit" style={{ color: 'silver' }}>No KTP :</label>
                    <input onInput={(e) => { e.target.value = e.target.value.slice(0, 20) }} onChange={this.ck} onKeyUp={validasiKTP} id="ktp" className="input-subject" type="number" name="no_ktp" min="0" defaultValue={this.state.no_ktp} placeholder="contoh: 3573041606010823" />
                    <div className="responsive-validation">
                      <a id="val_ktp" style={{ color: 'yellow' }}></a>
                    </div>
                  </div>
                </div>
                <div className="button-box">
                  <button className="submit-button" type="submit" style={{ backgroundColor: '#60be22' }}><i className="fa fa-save"></i>&nbsp;&nbsp;Simpan Perubahan</button>
                </div>
              </form>
            </div>
          </div>
          {/* end edit profile  */}
          {/* ubah password  */}
          <div className="leave-comment message">
            <h2 style={{ fontWeight: '600', fontSize: '22px', lineHeight: '16px', color: '#bbb7b7' }}><i className="fa fa-lock"></i>&nbsp; Edit Password</h2>
            <hr className="hr" />
            <form className="editpass" onSubmit={this.handleSubmit2} id="form_contact">
              <div>
                <label style={{ color: 'silver' }}>Password Lama :</label>
                <br />
                <input onChange={this.ck} onKeyUp={validasiPassword} className="input-subject" type="password" name="user_password" id="myInput" placeholder="Password Lama *"></input>
                <a className="fa fa-eye" onClick={this.showPw} id="asem" style={{ marginLeft: '-27px', marginTop: '31px', position: 'absolute' }} />
                <div className="responsive-validation">
                  <a id="2" style={{ color: 'red' }}></a>
                  <a id="22" style={{ color: 'yellow' }}></a>
                </div>
                <br />
                <label style={{ color: 'silver' }}>Password Baru :</label>
                <br />
                <input onChange={this.ck} onKeyUp={validasiPassword3} id="myInput2" className="input-subject" type="password" name="passbaru" placeholder="Password Baru *"></input>
                <a className="fa fa-eye" onClick={this.showPw2} id="asemz" style={{ marginLeft: '-27px', marginTop: '31px', position: 'absolute', }} />
                <div className="responsive-validation">
                  <a id="4" style={{ color: 'red' }}></a>
                  <a id="44" style={{ color: 'yellow' }}></a>
                </div>
                <br />
                <label style={{ color: 'silver' }}>Re-Type Password Baru :</label>
                <br />
                <input onChange={this.ck} onKeyUp={validasiPassword2} id="myInput3" className="input-subject" type="password" name="re_password" placeholder="Re-Type Password *" ></input>
                <a className="fa fa-eye" onClick={this.showPw3} id="y" style={{ marginLeft: '-27px', marginTop: '31px', position: 'absolute', }} />
                <div className="responsive-validation">
                  <a id="3" style={{ color: 'red' }}></a>
                  <a id="33" style={{ color: 'yellow' }}></a>
                </div>
                <br />
              </div>
              <div className="comment-active">
                <button className="submit-button" type="submit" style={{ backgroundColor: '#60be22' }}>Ubah Password</button>
              </div>
            </form>
          </div>
          {/* end ubah password  */}
        </div>
        <div className="clearfix" />
        {/* footer  */}
        <footer className="footer-section">
          <section className="footer-about">
            <div className="footer-content">
              <figure>
                <img
                  className="footer-logo"
                  src="images/logoasia.png"
                  alt="footer logo"
                  style={{ width: "68%", opacity: "0.5" }}
                />
              </figure>
              <div className="footer-text">
                <br />
                <p>
                  Dashboard Staff Asia adalah platform berbasis website yang
                  bertujuan untuk mempermudah pengguna untuk mengakses berbagai
                  platform aplikasi website yang ada di Institut Asia Malang.
                </p>
              </div>
            </div>
          </section>
          <section className="footer-contact">
            <h2 className="footer-title">CONTACT INFO</h2>
            <div className="footer-content">
              <ul>
                <li>
                  <div className="contact-list footer-text">
                    <span>UPT Sistem Informasi Asia</span>
                  </div>
                </li>
                <li>
                  <div className="contact-list footer-text email">
                    <span>Email: upt_si@asia.ac.id</span>
                  </div>
                </li>
                <li>
                  <div className="contact-list footer-text phone">
                    <span>Office Phone: 302 / WA : 0882002552992</span>
                  </div>
                </li>
              </ul>
            </div>
          </section>
          <div className="clearfix" />
          <section className="footer-bottom">
            <div className="copyright">
              Copyright © <a href="#">Expert Division</a>
            </div>
          </section>
        </footer>
        {/* end footer  */}
      </Fragment>
    )
  }
}

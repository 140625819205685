import React, { Fragment, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import dashboard from '../pages/dashboard';
import editprofile from '../pages/editprofile';
import PopupsHRD from '../pages/popups/PopupsHRD';
import PopupsKUI from '../pages/popups/PopupsKUI';
import PopupsSIPA from '../pages/popups/PopupSIPA';
import PopupsSimakaDosen from '../pages/popups/PopupsSimakaDosen';

function Routes() {

    const [loginInfo] = useState(localStorage.getItem("un"))

    useEffect(() => {
        if (loginInfo == null) {
            window.location.replace("/loadingout");
        }
    })

    return (
        <Fragment>
            {
                loginInfo == null ? null :
                    <Route>
                        {/* home  */}
                        <Route path="/beranda" exact component={dashboard} />
                        {/* edit profile  */}
                        <Route path="/editprofile" exact component={editprofile} />

                        {/* apps  */}
                        <Route path="/hrd" exact component={PopupsHRD} />
                        <Route path="/kui" exact component={PopupsKUI} />
                        <Route path="/sipa" exact component={PopupsSIPA} />
                        <Route path="/simaka-dosen" exact component={PopupsSimakaDosen} />
                    </Route>
            }
        </Fragment>
    );
}

export default Routes;
import React, { Component } from 'react';
import Popup from '../../component/Popup';
import simakadosen_link from "../../axios/simakadosen_link";
import API_ASTOR from "../../axios/Api_astor";
import key_astor from "../../axios/key_astor";

export default class PopupsSimakaDosen extends Component {
    state = {
        linkDosen: '',
        idusers: ''
    }

    checkValParams = () => {
        if (this.props.location.state && this.props.location.state.id) {
            this.setState({
                linkDosen: simakadosen_link.baseURL + "dashboard/" + this.props.location.state.id
            })
        } else {
            window.location.replace('/beranda')
        }
    }

    componentDidMount = () => {
        this.checkValParams()
    }

    render() {
        return (
            <div>
                <Popup link={this.state.linkDosen} />
            </div>
        )
    }
}

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import API from "../axios/api";
import key from "../axios/key";
import API_ASTOR from "../axios/Api_astor";
import key_astor from "../axios/key_astor";
import API_SURAT from "../axios/Api_surat";
import key_surat from "../axios/key_surat";
import hrd_link from "../axios/hrd_link";

export default class Menu extends Component {
  state = {
    idusers: "",
    lsipa: "",
    lkui: "",
    lhrd: "",
    inboxsipa: "",
    notifsurat: 0,
    listApps: [],
    userData: [],
    notifHRD: 0,
    loginInfo: localStorage.getItem("un")
  };

  getIdUser = () => {
    const user = this.state.loginInfo;
    API_ASTOR.get("cari/id/dash/cari" + key_astor.baseURL + "&id=" + user).then((res) => {
      this.setState({
        idusers: res.data[0].val_page,
      }, async () => {
        this.getListAplikasi()
        const resUser = await API_ASTOR.get("hrd/loading/search/inview" + key_astor.baseURL + "&uid=" + this.state.idusers);
        this.setState({ userData: resUser.data[0] })
      });
    });
  }

  getListAplikasi = () => {
    const uid = this.state.loginInfo;
    API.get("aps/tampil/beranda/v" + key.baseURL + "&uid=" + uid).then((res) => {
      this.setState({
        listApps: res.data
      }, () => {
        this.getNotifSurat()
        this.getNotifHRD()
      });
    });
  }

  getNotifSurat = () => {
    let uid = this.state.loginInfo;
    API_SURAT.get("dash/cari/jumlah/surat/masuk/cari" + key_surat.baseURL + "&id=" + uid).then((res) => {
      this.setState({
        notifsurat: res.data[0].jsurat,
      });
    });
  }

  getNotifHRD = async () => {
    const resDate = await API_ASTOR.get('date/now' + key_astor.baseURL);
    const resNotif = await API_ASTOR.get("notif/all" + key_astor.baseURL + '&id=' + localStorage.getItem('un') + '&tahun=' + resDate.data[0].year);
    this.setState({
      notifHRD: parseInt(resNotif.data.notif_jadwalDosen) + parseInt(resNotif.data.notif_izinDosen) + parseInt(resNotif.data.notif_jadwalKaryawan) + parseInt(resNotif.data.notif_izinKaryawan)
    })
  }

  findApps(idp) {
    const scr = this.state.listApps.find((lpr) => lpr.pid === idp);
    if (scr) {
      return scr.pid;
    }
  }

  openDropdown = () => {
    document.getElementById("dropdownOpen").classList.toggle("show");
  }

  componentDidMount = () => {
    this.getIdUser()
  }

  render() {
    if (this.state.loginInfo != null) {
      if (localStorage.getItem("nama") != "" || localStorage.getItem("nama") != null) {
        var nama = localStorage.getItem("nama");
        if (nama.length > 12) {
          nama = nama.substring(0, 12) + "..";
        }
      }
    }
    return (
      this.state.loginInfo == null ? null :
        <Fragment>
          <div className="wrapper">
            <section className="call-action">
              <div className="content">
                <figure>
                  <img src="images/logoasia.png" className="logoku" />
                  <div className="profil">
                    <div className="dropdown" onClick={this.openDropdown}>
                      <span className="fa fa-user-circle" />
                      &nbsp; {nama} &nbsp;
                      <span className="fa fa-angle-down" />
                      <div className="dropdown-content" id="dropdownOpen">
                        <Link to="/editprofile">
                          <div style={{ padding: '20px 35px 10px 24px' }}>
                            <p style={{ color: "white", fontSize: "12pt" }}>Profile</p>
                          </div>
                        </Link>
                        <Link to="/loadingout">
                          <div style={{ padding: '10px 35px 20px 24px' }}>
                            <p style={{ color: "#e64961", fontSize: "12pt" }}><span className="fa fa-sign-out" /> Logout</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </figure>
              </div>
            </section>
            {/* left position view box  */}
            <div className="grid-half left" style={{ marginBottom: "23px" }}>
              {/* banner  */}
              <div className="large-grid flip">
                <div className="flip-front" id="idbg" style={{ width: "100%" }}>
                  <figure>
                    <img src="images/homepage.svg" alt="image" />
                  </figure>
                </div>
                <div className="flip-back">
                  <h1 className="large-title-back">DASHBOARD STAFF ASIA</h1>
                  <p className="large-description-back">
                    <b>Hi ! </b>
                    {localStorage.getItem("nama")}
                  </p>
                  <br />
                  <p>
                    Dashboard Staff Asia adalah platform berbasis website yang
                    bertujuan untuk mempermudah pengguna untuk mengakses berbagai
                    platform aplikasi website yang ada di Institut Asia Malang.
                  </p>
                </div>
              </div>
            </div>
            {/* end left position view box  */}
            {/* right position view box  */}
            <div className="grid-half right">
              <ul className="small-grid">

                {/* aplikasi sipa */}
                <li className="flip">
                  <div className="flip-front" id="idbg">
                    {
                      this.state.notifsurat != 0 ?
                        <div
                          style={{
                            padding: "11px 13px 11px 13px",
                            backgroundColor: "#e3243b",
                            width: "17.5px",
                            borderRadius: "50%",
                            color: "white",
                            position: "absolute",
                            marginTop: "-4%",
                            marginLeft: "-4%",
                            height: "20px",
                          }}
                          align="center"
                        >
                          <span style={{ marginLeft: "auto", marginRight: "auto" }}>
                            {this.state.notifsurat}
                          </span>
                        </div>
                        : null
                    }
                    <figure>
                      <img src="images/letter.svg" alt="image" />
                    </figure>
                  </div>
                  <div className="flip-back active-details gray">
                    <h2 className="title-back text-gray">SIPA</h2>
                    <p className="description-back text-dark">
                      Sistem Informasi Pengarsipan Surat Menyurat
                    </p>
                    <br />
                    <br />
                    <Link id="smSipaBtn"
                      to={{
                        pathname: `/sipa`,
                        state: {
                          id: this.state.idusers,
                          role: "inbox"
                        }
                      }}
                    >
                      <div className="more-details" style={{ bottom: "unset" }}>
                        Pesan
                      </div>
                    </Link>
                    <br />
                    <br />
                    {this.state.listApps.map((pps, i) => {
                      if (this.findApps(pps.pid) == 1) {
                        return (
                          <Fragment key={i}>
                            {pps.pid == 1 ?
                              <Link
                                to={{
                                  pathname: `/sipa`,
                                  state: {
                                    id: this.state.idusers,
                                    role: "sipa"
                                  }
                                }}
                              >
                                <div className="more-details" style={{ bottom: "unset" }}>
                                  Buka SIPA
                                </div>
                              </Link>
                              : null}
                          </Fragment>
                        )
                      }
                    })}
                  </div>
                </li>
                {/* end aplikasi sipa  */}

                {/* aplikasi hrd  */}
                <li className="flip">
                  <div className="flip-front" id="idbg">
                    {
                      this.state.notifHRD != 0 ?
                        <div
                          style={{
                            padding: "11px 13px 11px 13px",
                            backgroundColor: "#e3243b",
                            width: "17.5px",
                            borderRadius: "50%",
                            color: "white",
                            position: "absolute",
                            marginTop: "-4%",
                            marginLeft: "-4%",
                            height: "20px",
                          }}
                          align="center"
                        >
                          <span style={{ marginLeft: "auto", marginRight: "auto" }}>
                            {this.state.notifHRD}
                          </span>
                        </div>
                        : null
                    }
                    <figure>
                      <img src="images/icon_hrd.svg" alt="image" />
                    </figure>
                  </div>
                  <br></br>
                  <div className="flip-back active-details gray">
                    <h2 className="title-back text-gray">HRD ASIA</h2>
                    <p className="description-back text-dark">
                      Sistem Informasi Kepegawaian Institut Asia
                    </p>
                    <br />
                    <br />
                    <Link id="hrdBtn" to={{ pathname: `/hrd`, state: { id: this.state.idusers } }}>
                      <div className="more-details">Buka dashboard</div>
                    </Link>
                  </div>
                </li>
                {/* end aplikasi hrd  */}

                {/* simaka dosen  */}
                {this.state.userData.posisi1 == "Karyawan" || this.state.userData.length == 0 ? null :
                  <li className="flip">
                    <div className="flip-front" id="idbg">
                      <figure>
                        <img src="images/icon_akademik.svg" alt="image" />
                      </figure>
                    </div>
                    <div className="flip-back active-details gray">
                      <h2 className="title-back text-gray">SIMAKA DOSEN</h2>
                      <p className="description-back text-dark">Aplikasi Simaka Dosen Institut Asia Malang</p>
                      <br />
                      <br />
                      <Link id="kuiBtn"
                        to={{
                          pathname: `/simaka-dosen`,
                          state: {
                            id: this.state.idusers,
                          }
                        }}
                      >
                        <div className="more-details">Buka dashboard</div>
                      </Link>
                    </div>
                  </li>
                }
                {/* end simaka dosen  */}

                {/* list apps  */}
                {this.state.listApps.map((list) => {
                  if (this.findApps(list.pid) == 3) {
                    return (
                      <li className="flip">
                        <div className="flip-front" id="idbg">
                          <figure>
                            <img src="images/icon_kui.svg" alt="image" />
                          </figure>
                        </div>
                        <div className="flip-back active-details gray">
                          <h2 className="title-back text-gray">KUI ASIA</h2>
                          <p className="description-back text-dark">Aplikasi administrator KUI Institut Asia Malang</p>
                          <br />
                          <br />
                          <Link id="kuiBtn"
                            to={{
                              pathname: `/kui`,
                              state: {
                                id: this.state.idusers,
                              }
                            }}
                          >
                            <div className="more-details">Buka dashboard</div>
                          </Link>
                        </div>
                      </li>
                    )
                  }
                })}
                {/* end list apps  */}

              </ul>
            </div>
            {/* end right position view box  */}
          </div>
          <div className="clearfix" />
          {/* footer  */}
          <footer className="footer-section">
            <section className="footer-about">
              <div className="footer-content">
                <figure>
                  <img
                    className="footer-logo"
                    src="images/logoasia.png"
                    alt="footer logo"
                    style={{ width: "68%", opacity: "0.5" }}
                  />
                </figure>
                <div className="footer-text">
                  <br />
                  <p>
                    Dashboard Staff Asia adalah platform berbasis website yang
                    bertujuan untuk mempermudah pengguna untuk mengakses berbagai
                    platform aplikasi website yang ada di Institut Asia Malang.
                  </p>
                </div>
              </div>
            </section>
            <section className="footer-contact">
              <h2 className="footer-title">CONTACT INFO</h2>
              <div className="footer-content">
                <ul>
                  <li>
                    <div className="contact-list footer-text">
                      <span>UPT Sistem Informasi Asia</span>
                    </div>
                  </li>
                  <li>
                    <div className="contact-list footer-text email">
                      <span>Email: upt_si@asia.ac.id</span>
                    </div>
                  </li>
                  <li>
                    <div className="contact-list footer-text phone">
                      <span>Office Phone: 302 / WA : 0882002552992</span>
                    </div>
                  </li>
                </ul>
              </div>
            </section>
            <div className="clearfix" />
            <section className="footer-bottom">
              <div className="copyright">
                Copyright © <a href="#">Expert Division</a>
              </div>
            </section>
          </footer>
          {/* end footer  */}
        </Fragment>
    );
  }
}

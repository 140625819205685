import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Popup.css';

export default class Popup extends Component {
    render() {
        const apps = (
            <div className="modale opened" aria-hidden="true">
                <div className="modal-dialog">
                    <div className='wrapper-mobile-close'>
                        <Link to="/beranda">
                            <div className='mobile-close'>
                                <p className='text-close-mobile'>
                                    <i className='fa fa-times' />&nbsp;&nbsp;CLOSE APP
                                </p>
                            </div>
                        </Link>
                    </div>
                    <iframe id="myframe" className='iframe-popup' src={this.props.link}></iframe>
                    <Link to="/beranda" className="float">
                        <i className="fa fa-times my-float"></i>
                        <p className='text-btn'><i className="fa fa-times"></i>&nbsp;&nbsp;CLOSE APP</p>
                    </Link>
                </div>
            </div>
        );
        return (
            <div>
                {apps ? apps : ""}
            </div>
        );
    }
}

import React, { Component, Fragment } from 'react';
import API from '../axios/api';
import key from '../axios/key';

export default class login extends Component {

  state = {
    user_name: '',
    user_password: '',
    filedatas: '',
    idus: ''
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleLogin = async (event) => {
    document.getElementById('btn-add').innerHTML = '<i class="fa fa-spinner fa-spin"></i>'
    document.getElementById('btn-add').disabled = true
    event.preventDefault()
    await API.post('/login/dashboard/ul' + key.baseURL, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas,
          idus: Response.data[0].idus
        })
      )
    this.ceklogin()
  }

  ceklogin() {
    if (this.state.filedatas == 1) {
      this.props.history.push({
        pathname: '/loading',
        state: { idUser: this.state.idus }
      })
    } else {
      alert("Gagal login! info hubungi WA : 0882002552992")
      document.getElementById('btn-add').innerHTML = "Sign In"
      document.getElementById('btn-add').disabled = false
    }
  }

  componentDidMount() {
    const elem = document.getElementById('startingLoader');
    window.onload = () => {
      if (elem) {
        elem.remove();
      }
    };
    if (localStorage.getItem("un") == null) {
      localStorage.clear()
    } else {
      window.location.replace('/beranda')
    }
  }

  // show password 
  showPw() {
    var x = document.getElementById('myInput')
    if (x.type === 'password') {
      x.type = 'text'
      document.getElementById('showPw').style.color = '#32a2e3'
    } else {
      x.type = 'password'
      document.getElementById('showPw').style.color = '#919191'
    }
  }
  // end show password

  render() {
    return (
      <Fragment>
        {
          localStorage.getItem("un") == null ?
            <div className='wrapper-first'>
              <div className="main">
                <div className='login-wrapper'>
                  <img src="images/logoasia.png" className='logo-login' />
                  <p className="sign" align="center">Sign In | Staff Asia</p>
                  <form onSubmit={this.handleLogin}>
                    <input className="un" type="text" align="center" name="user_name" onChange={this.handleChange} placeholder="User ID" />
                    <input className="pass" id="myInput" type="password" align="center" name="user_password" onChange={this.handleChange} placeholder="Password" />
                    <a style={{ position: 'absolute', marginTop: '13px', marginLeft: '-30px' }} className="fa fa-eye" onClick={this.showPw} id="showPw" />
                    <br />
                    <br />
                    <p align="center">
                      <button className="submit" type="submit" align="center" id="btn-add">
                        Sign In
                      </button>
                    </p>
                  </form>
                </div>
              </div>
            </div>
            :
            <div id="preloader">
              <div className="jumper">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
        }
      </Fragment>
    );
  }
}

import React, { Component } from 'react';
import Popup from '../../component/Popup';
import app_surat from "../../axios/app_surat";

export default class PopupsSIPA extends Component {
    state = {
        lsipa: '',
        inboxsipa: '',
        idusers: '',
        role: '',
    }

    checkValParams = () => {
        if (this.props.location.state && this.props.location.state.id) {
            this.setState({
                idusers: this.props.location.state.id,
                role: this.props.location.state.role
            }, () => {
                this.getTheLink()
            })
        } else {
            window.location.replace('/beranda')
        }
    }

    getTheLink = () => {
        const unID = localStorage.getItem("unlog");
        const akses = localStorage.getItem("inlog");
        this.setState({
            lsipa:
                app_surat.baseURL +
                "linkdash/" +
                this.state.idusers +
                "/" +
                akses +
                "/" +
                unID,
            inboxsipa:
                app_surat.baseURL +
                "linkinbox/" +
                this.state.idusers +
                "/" +
                akses +
                "/" +
                unID,
        });
    }

    componentDidMount = () => {
        this.checkValParams()
    }
    render() {
        return (
            <div>
                <Popup link={this.state.role == "inbox" ? this.state.inboxsipa : this.state.lsipa} />
            </div>
        )
    }
}

import React, { Component } from 'react';
import Popup from '../../component/Popup';
import hrd_link from "../../axios/hrd_link";
import API_ASTOR from "../../axios/Api_astor";
import key_astor from "../../axios/key_astor";

export default class PopupsHRD extends Component {
    state = {
        linkHRD: '',
        idusers: ''
    }

    checkValParams = () => {
        if (this.props.location.state && this.props.location.state.id) {
            this.setState({
                idusers: this.props.location.state.id
            }, () => {
                this.getTheLink()
            })
        }else{
            window.location.replace('/beranda')
        }
    }

    getTheLink = () => {
        API_ASTOR.get("hrd/loading/search/inview" + key_astor.baseURL + "&uid=" + this.state.idusers).then((res) => {
            this.setState({
                adm: res.data[0].aks,
            }, () => {
                this.setState({
                    linkHRD: hrd_link.baseURL + "linkdashhrd/" + this.state.idusers + "/" + this.state.adm
                })
            });
        });
    }

    componentDidMount = () => {
        this.checkValParams()
    }

    render() {
        return (
            <div>
                <Popup link={this.state.linkHRD} />
            </div>
        )
    }
}

import React, { Component } from 'react'
import API from '../axios/api'
import key from '../axios/key'

export default class login extends Component {
  state = {
    un: '',
    ua: '',
    user_nama: '',
    pssny: '',
    userid: ''
  }

  componentDidMount = async () => {
    let checkParams = this.props.location.state;
    if (checkParams == null || checkParams == "") {
      window.location.replace('/loadingout')
    } else {
      const idUser = checkParams.idUser;
      const res = await API.get('surat/search/lv/inview' + key.baseURL + '&uid=' + idUser)
      this.setState({
        ua: res.data[0],
        un: res.data[0].usid,
        user_nama: res.data[0].user_nama,
        pssny: res.data[0].pss,
        userid: res.data[0].user_id
      })
      localStorage.setItem("un", this.state.un)
      localStorage.setItem("unlog", this.state.pssny)
      localStorage.setItem("inlog", this.state.userid)
      localStorage.setItem("nama", this.state.user_nama)
      this.validasiLogin()
    }
  }

  validasiLogin() {
    if (localStorage.getItem("un") == null) {
      window.location.replace('/loadingout')
    }
    else {
      window.location.replace('/beranda')
    }
  }

  render() {
    return (
      <div id="preloader">
        <div className="jumper">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }
}
import React, { Component } from 'react';
import Popup from '../../component/Popup';
import kui_link from "../../axios/kui_link";

export default class PopupsKUI extends Component {
    state = {
        linkKUI: '',
        idusers: ''
    }

    checkValParams = () => {
        if (this.props.location.state && this.props.location.state.id) {
            this.setState({
                idusers: this.props.location.state.id
            }, () => {
                this.getTheLink()
            })
        } else {
            window.location.replace('/beranda')
        }
    }

    getTheLink = () => {
        const unID = localStorage.getItem("unlog");
        const akses = localStorage.getItem("inlog");
        this.setState({
            linkKUI:
                kui_link.baseURL +
                "linkdashkui/" +
                this.state.idusers +
                "/" +
                akses +
                "/" +
                unID,
        });
    }

    componentDidMount = () => {
        this.checkValParams()
    }
    render() {
        return (
            <div>
                <Popup link={this.state.linkKUI} />
            </div>
        )
    }
}

import React, { Component } from 'react'

export default class login extends Component {
  componentDidMount() {
    localStorage.clear()
    window.location.replace('/')
  }
  render() {
    return (
      <div id="preloader">
        <div className="jumper">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }
}